// /auth/dialog/authorize

'use strict';

angular.module('enervexSalesappApp').controller('DialogCtrl', function($scope, Auth, $stateParams, $cookies, $http) {
	var oauth =JSON.parse($cookies.get("oauth"))
	$scope.user = oauth.user;
	$scope.client = oauth.client
	$scope.transactionID = oauth.transactionID;

	Auth.getSession().then(function() {
		console.log("you are logged in")
	}).catch(function(e){
		window.location.href="/auth/login"
	})
	$scope.relogin = function() {
		Auth.logout().then(function(data){
			if (data.redirect) {
				window.location.href = data.redirect;
			} else {
				window.location.href = "/";
			}
		}).catch(function(data){
			window.location.href = "/";
		})
	};
	$scope.cancel = function() {
		Auth.cancel().then(function(data){
			console.log("data: "+JSON.stringify(data))
			window.location.href = "/";
		}).catch(function(data){
			console.log("error data: "+JSON.stringify(data))
			window.location.href = "/";
		})
	}
});
